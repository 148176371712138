import emailjs from '@emailjs/browser';
import axios from 'axios';
import { BlogType } from "../data/types";

export type ContactFormDataType = {email: string, name: string, tel: string, text: string, files: Array<string>}
export type CompleteApplicantType = {
    visa_status: string
    fullName: string
    tel: string
    email: string
    service: string
    numberOfApplicants: string
    fullPrice: number
    html: string
    uid: string | undefined
    commentData: string
}
export type sendCompleteToUserType = {
    uid: string | undefined
    fullName: string
    email: string
    service: string
    numberOfApplicants: string
    fullPrice: number
    commentData: string
}

export type BadPhotoComplete = {
    uid: string | undefined,
    email: string,
    newPhotosAndNamesHtml: string
}
export type PaymentComplete = {
    uid: string | undefined,
    fullPrice: string,
    paymentType: string,
    email: string,
    paymentReceiptsHtml: string
}

export type sendMessageService = {
    email: string
    name: string
    tel: string
    text: string
    guests: number
    service_name: string
    date: string
}

export const mailJsAPI = {
    sendFromContactForm: (data: ContactFormDataType) => {
        emailjs.send('service_m39iwem', 'template_3zwovbv', data, 'vSKmUbyVythvPMnCi')
    },
    sendCompleteApplicant: (data: CompleteApplicantType) => {
        emailjs.send('service_m39iwem', 'template_8jle44g', data, 'vSKmUbyVythvPMnCi')
    },
    sendCompleteToUser: (data: sendCompleteToUserType) => {
        emailjs.send('service_m39iwem', 'template_xjrfmji', data, 'vSKmUbyVythvPMnCi')
    },
    sendBadPhotoComplete: (data: BadPhotoComplete) => {
        emailjs.send('service_m39iwem', 'template_414hsyo', data, 'vSKmUbyVythvPMnCi')
    },
    sendPaymentSuccessToAdmin: (data: PaymentComplete) => {
        emailjs.send('service_m39iwem', 'template_qtwus87', data, 'vSKmUbyVythvPMnCi')
    },
    sendServiceRequest:(data:sendMessageService) => {
        emailjs.send('service_m39iwem', 'template_g5gtxn3', data, 'vSKmUbyVythvPMnCi')

    }
}

export const getCurrentDateTime = async () => {
    let res = await axios.get('https://sentosastripeserver-production.up.railway.app/datetime')
    return res.data
}

export const getBlogsData = async () => {
    return await axios.get<Array<BlogType>>('https://sentosatelegrambot-production.up.railway.app/blogs').then(res => res.data)
}